<template>
    <div :class="'game-window' + ( isGameFinished ? ' finished' : '')">
        <div class="background"></div>

        <div :class="'tree' + (isTreeHit ? ' get-hit' : '')">
            <div class="tree-shadow"></div>
            <img src="../assets/tree.png" alt="tree" />
            <div class="tree-hp">
                <span class="tree-hp-text">
                    {{ treeCurrentHealth }}/{{ treeMaxHealth }}
                </span>
                <div class="bar-wrapper">
                    <div class="bar" :style="{ width: ( treeHealth <= 0 ? 0 : treeHealth ) + '%' }"></div>
                </div>
            </div>
            <div :class="'tree-status-text' + ( isDisplayingMessage ? ' show' : ' hide' ) + ( isAnimatingText ? ' animate' : '' )">
                <p>{{ currentMessage }}</p>
            </div>
        </div>


        <div class="strike-button">
            <button @click="strike"><img src="../assets/Axe_v2_05.png" alt="Axe" /></button>
        </div>

        <div class="hit-meter">
            <div class="middle-tick" :style="{ left: 'calc(' + currentTickPosition + '% - 4px)' }"></div>
        </div>

        <div class="game-summary">
            <div class="summary-header">
                <h1>Tree Chopped!</h1>
            </div>
            <div class="summary-body">
                <h2>Your score</h2>
                <p>
                    <span>Total Wood: </span>
                    <span class="number">{{collected}}</span>
                </p>
                <p>
                    <span>Hits Taken: </span>
                    <span class="number">{{totalHits}}</span>
                </p>
                <p>
                    <span>Total Score: </span>
                    <span class="number">{{totalScore}}</span>
                </p>
                <p>
                    <button @click="startGame">Play Again</button>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'

export default {
    name: 'ChoppingGame',
    computed: {
        treeCurrentHealth() {
            return this.treeHealth < 0 ? 0 : this.treeHealth
        },
    },
    methods: {
        strike() {
            if (!this.isGameFinished) {
                const soundRandom = Math.floor( Math.random() * 8 )

                this.sounds[soundRandom].play()

                this.totalHits++

                let powerLevel = this.currentTickPosition - 50

                if (powerLevel > 0) {
                    powerLevel = 0 - powerLevel
                }

                powerLevel = ( powerLevel * -1 ) * 2

                powerLevel = Math.ceil( this.maxDamage - ( this.maxDamage * ( powerLevel / 100 ) ) )

                if (powerLevel == this.maxDamage) {
                    this.perfectHits++
                }

                this.isTreeHit = true
                this.displayMessage( powerLevel )

                this.treeHealth -= powerLevel

                setTimeout( () => {
                    this.isTreeHit = false

                    if (this.treeHealth <= 0) {

                        var audio = new Audio(require('../sounds/TreeFall.wav'))
                        audio.play()

                        this.isGameFinished = true
                        const minHits = Math.ceil( this.treeHealth / this.maxDamage )
                        const extraHits = this.totalHits - minHits

                        let topScore = 1000 - ( extraHits * 50 )
                        topScore += ( this.perfectHits * 100 )

                        this.totalScore = topScore
                        this.collected = 30 - ( extraHits * 2 )

                        const minCollection = 3

                        this.collected += ( this.perfectHits * 2 )

                        if (this.collected < minCollection) {
                            this.collected = minCollection
                        }

                        if (this.collected > 30) {
                            this.collected = 30
                        }
                    }
                }, 1000)
            }
        },
        displayMessage( message ) {
            this.currentMessage = message
            this.isDisplayingMessage = true

            this.isAnimatingText = true

            setTimeout( () => {
                this.isDisplayingMessage = false
            }, 500)
        },
        startGame(){
            this.isGameFinished = false
            this.treeHealth = 100
            this.totalHits = 0
            this.perfectHits = 0
            this.totalScore = 0
            this.collected = 0
            this.currentTickPosition = 50
            this.treeMaxHealth = this.treeHealth

            const soundcount = 7

            for (let index = 0; index < soundcount; index++) {
                const element = "Chop Tree " + ( soundcount + 1 ) + ".mp3";
                this.sounds.push( new Audio( require("../sounds/" + element) ) )
            }

            this.intervalId = setInterval( () => {
                if (this.isGameFinished) {
                    clearInterval( this.intervalId )
                }

                if (this.isTickMovingRight && this.currentTickPosition < 100) {
                    this.currentTickPosition++
                    return;
                } 
                
                if (this.isTickMovingRight && this.currentTickPosition == 100) {
                    this.isTickMovingRight = false
                    this.currentTickPosition--;
                    return;
                }

                if (!this.isTickMovingRight && this.currentTickPosition > 0) {
                    this.currentTickPosition--
                    return;
                }

                if (!this.isTickMovingRight && this.currentTickPosition == 0) {
                    this.isTickMovingRight = true
                    this.currentTickPosition++;
                    return;
                }
            }, 16)
        }
    },
    setup() {
        const isTreeHit = ref(false)
        const hitCount = ref(0)
        const treeHealth = ref(100)
        const treeMaxHealth = ref(0)
        const maxDamage = ref(20)
        const currentTickPosition = ref(50)
        const isTickMovingRight = ref(true)
        const currentMessage = ref('')
        const isDisplayingMessage = ref(false)
        const isAnimatingText = ref(false)
        const isGameFinished = ref(false)
        const totalHits = ref(0)
        const totalScore = ref(0)
        const perfectHits = ref(0)
        const collected = ref(0)
        const intervalId = ref(0)
        const sounds = ref([])

        return {isTreeHit, hitCount, treeHealth, treeMaxHealth, maxDamage, sounds, currentTickPosition, isTickMovingRight, currentMessage, isDisplayingMessage, isAnimatingText, isGameFinished, totalHits, totalScore, perfectHits, collected, intervalId}
    },
    mounted() {
        this.startGame()
    }
}
</script>

<style scoped>
    .game-window{
        background: #000;
        top: 0;
        left: 0;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        cursor: url(https://cdn.dragoncrypto.io/uiassets/normal_cursor_gray.png), auto;
    }

    .game-window .background{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('../assets/WoodCutting_New.jpg') no-repeat top left;
        background-size: cover;
    }

    .game-summary{
        position: absolute;
        transform: translateY(-100%);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        color: #fff;
        background: #000;
        padding: 20px;
        overflow: hidden;
        font-size: 200%;
        z-index: 100;
    }

    .finished .game-summary{
        transform: translateY(0);
        transition: transform 0.5s ease-in-out;
    }

    .game-summary button{
        font-size: 130%;
        padding: 20px 25px;
        margin-top: 20px;
        border-radius: 10px;
    }

    .summary-body .number{
        font-size: 150%;
        font-weight: normal;
    }

    .game-window .tree{
        position: relative;
        top: 22vh;
        left: calc( 50% - 130px );
        width: 260px;
    }

    .game-window .tree-shadow{
        content: ' ';
        left: 37px;
        top: 585px;
        width: 300px;
        height: 500px;
        position: absolute;
        background: url('../assets/tree-shadow.png') no-repeat top left;
        background-size: contain;
    }

    .tree img{
        max-width: 100%;
        filter: drop-shadow(0px 0px 10px #fff);
        transform-origin: 50% 100%;
    }

    .tree.get-hit .tree-shadow{
        transform-origin: 40% 0;
        animation: tree-shake 0.7s 1 ease-out;
    }

    .tree.get-hit img{
        animation: tree-shake 0.7s 1 ease-out;
    }

    .finished .tree img{
        animation: tree-fall 0.5s 1 ease-in-out both;
        transform-origin: 50% 100%;
    }

    .tree .tree-hp-text{
        color: #fff;
        font-family: "Lato", sans-serif;
        display: block;
        left: 50%;
        transform: translateX(-50%);
        font-size: 0.9rem;
        text-shadow: 0 0 2px #000;
        z-index: 1;
        position: absolute;
    }

    .tree .tree-hp{
        top: 700px;
        left: calc( 50% - 150px );
        width: 273px;
        height: 28px;
        background: #161411;
        display: block;
        position: absolute;
        background: url(https://cdn.dragoncrypto.io/uiassets/health-bar.png) center center;
        background-size: contain;
        background-repeat: no-repeat;
        display: grid;
        align-items: center;
        padding: 0 22px;
    }

    .tree .tree-hp .bar-wrapper{
        width: 260px;
        height: 12px;
        position: absolute;
        left: 29px;
    }

    .tree .tree-hp .bar{
        background-color: red;
        left: 0;
        position: absolute;
        height: 100%;
        box-shadow: inset 0 0 10px rgb(0 0 0 / 90%);
        transition: width 0.5s linear;
    }

    .tree-status-text{
        position: absolute;
        top: 0;
        left: calc( 50% - 100px );
        width: 200px;
        text-align: center;
        font-size: 2.5rem;
        font-family: "Lato", sans-serif;
        color: red;
    }

    .tree-status-text.show{
        display: block;
    }

    .tree-status-text.animate{
        animation: text-fade-up 0.5s infinite ease-out;
    }

    .tree-status-text.hide{
        display: none;
    }

    .strike-button{
        position: absolute;
        bottom: 128px;
        width: 128px;
        height: 128px;
        left: calc( 50% - 64px );

        
    }

    .strike-button button{
        border: 0;
        width: 130px;
        height: 130px;
        background-color: transparent;
        background-image: url(https://cdn.dragoncrypto.io/uiassets/attack-button.png);
        background-size: contain;
        cursor: url(https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png), auto;
        transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
        transition-duration: 200ms;
    }

    .strike-button button:after{
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url(https://cdn.dragoncrypto.io/uiassets/attack-button-shine.png);
        top: 0;
        left: 0;
        z-index: 2;
        background-size: cover;
        transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transition-duration: 400ms;
    }

    .strike-button button:hover{
        transform: scale(1.1, 1.1) translate3d(0, 0, 0);
    }

    .strike-button button:hover:after{
        transform: rotate(10deg);
    }

    .strike-button img{
        max-width: calc( 100% - 20px );
    }

    .hit-meter{
        position: absolute;
        bottom: 48px;
        left: calc( 50% - 250px);
        width: 500px;
        height: 24px;
        border-radius: 3px;
        border: 1px solid rgba(0,0,0,0.5);
        background: linear-gradient(90deg, rgba(180,0,0,0.6) 0%, rgba(228,217,0,0.7) 25%, rgba(48,229,0,0.7) 42%, rgba(26,255,0,0.9) 50%, rgba(49,230,0,0.7) 58%, rgba(233,222,1,0.7) 75%, rgba(170,0,0,0.6) 100%);
    }

    .hit-meter:after{
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        background-image: url(https://cdn.dragoncrypto.io/uiassets/message-bottom.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        height: 30px;
        bottom: -29px;
    }

    .hit-meter .middle-tick{
        position: absolute;
        width: 8px;
        height: 100%;
        background: #000;
        border-radius: 5px;
        top: 0;
    }

    @keyframes tree-shake{
        0%{
            transform: rotate(0);
        }
        50%{
            transform: rotate(-0.7deg);
        }
        25%, 75%{
            transform: rotate(0.7deg);
        }
        100%{
            transform: rotate(0);
        }
    }

    @keyframes text-fade-up{
        0%{
            transform: translateY(0);
        }
        70%{
            opacity: 1;
        }
        100%{
            opacity: 0;
            transform: translateY(-100px);
        }
    }

    @keyframes tree-fall{
        0%{
            opacity: 1;
            transform: rotate(0);
        }
        95%{
            transform: rotate(90deg);
        }
        97%{
            transform: rotate(89deg);
        }
        100%{
            opacity: 0;
            transform: rotate(90deg);
        }
    }
</style>