import { createApp } from 'vue'
import App from './App.vue'
import HelloWorld from './components/HelloWorld.vue'
import MiningGame from './components/MiningGame.vue'
import ChoppingGame from './components/ChoppingGame.vue'
import {createRouter,createWebHashHistory} from 'vue-router'

const routes = [
    { path: '/', component: HelloWorld },
    { path: '/mining', component: MiningGame },
    { path: '/chopping', component: ChoppingGame },
  ]

const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
})
  

const app = createApp(App)

app.use(router)

app.mount('#app')
