<template>
  <div class="game-window">
    <div class="background" id="playarea" :style="{ backgroundPositionY: backgroundPosition }">
        <div 
            v-for="ore in oreStatus" :key="ore.xPosition" 
            :class="'ore' + ( ore.visible ? ' show' : '' ) + ( ore.isInHitZone ? ' is-in-zone' : '') + ( ore.destroyed ? ' destroyed' : '' )" 
            :style="{ top: ore.windowPosition + 'px', left: ore.xPosition + 'px' }"></div>
    </div>
    <div id="hit-zone" :class="( isOreInHitZone ? 'active' : '' )"></div>
    <div class="ticker">{{tickerValue}}</div>
    <div :class="'strike-button' + ( isOreInHitZone ? ' active' : '' ) + ( strikesRemaining <= 0 ? ' disabled' : '' )">
      <button @click="strike">Strike</button>
    </div>
    <div class="collected">
        <img v-for="n in collected" :key="n" src="../assets/Mining_05_ironore.png" alt="Iron Ore" />
    </div>
    <div class="strikes-remaining">
        <p>Strikes Remaining: {{ strikesRemaining }}</p>
    </div>
  </div>
  <div :class="'game-summary' + (isGameFinished ? ' show' : '')">
    <div class="summary-header">
        <h1>Vein Completed!</h1>
    </div>
    <div class="summary-body">
        <h2>Your score</h2>
        <p>
            <span>Total Ore: </span>
            <span class="number">{{collected}}</span>
        </p>
        <p>
            <span>Total Strikes: </span>
            <span class="number">{{totalStrikes}}</span>
        </p>
        <p>
            <span>Total Score: </span>
            <span class="number">{{totalScore}}</span>
        </p>
        <p>
            <button @click="startGame">Play Again</button>
        </p>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'MiningGame',
  setup() {
    const playAreaWidth = ref(0)
    const clientWindowHeight = ref(window.innerHeight)
    const clientWindowWidth = ref(window.innerWidth)
    const currentScrollPosition = ref(0)
    const oreWidth = ref(64)
    const ticksPerSecond = ref(30)
    const oreStatus = ref([])
    const tickerValue = ref(0)
    const hitZoneTop = ref(0)
    const hitZoneBottom = ref(0)
    const collected = ref(0)
    const totalStrikes = ref(0)
    const totalScore = ref(0)
    const isGameFinished = ref(false)
    const mapLength = ref(5000)
    const strikesRemaining = ref(0)

    return { 
        currentScrollPosition, ticksPerSecond, oreStatus, clientWindowHeight, clientWindowWidth, oreWidth, 
        playAreaWidth, tickerValue, hitZoneTop, hitZoneBottom, collected, totalStrikes, totalScore, isGameFinished,
        mapLength, strikesRemaining
    }
  },
  computed: {
    backgroundPosition() {
      return ( 0 - this.currentScrollPosition ) + "px"
    },
    isOreInHitZone() {
        return this.oreStatus.some(ore => ore.isInHitZone)
    }
  },
  methods: {
    startGame() {
        this.playAreaWidth = document.getElementById('playarea').clientWidth
    
        const hitZoneElement = document.getElementById("hit-zone")

        this.hitZoneTop = hitZoneElement.offsetTop
        this.hitZoneBottom = hitZoneElement.offsetTop + hitZoneElement.clientHeight

        const getRandomOre = ( playAreaWidth ) => {
            return { visible: false, scrollPosition: Math.floor( Math.random() * this.mapLength ), destroyed: false, windowPosition: 0, isInHitZone: false, xPosition: Math.floor( Math.random()*(playAreaWidth - this.oreWidth) ) }
        }

        const oresToGen = 40

        this.currentScrollPosition = 0
        this.oreStatus = []
        this.collected = 0
        this.totalStrikes = 0
        this.totalScore = 0
        this.isGameFinished = false
        this.strikesRemaining = oresToGen

        for (let index = 0; index < oresToGen; index++) {
            this.oreStatus[index] = getRandomOre(this.playAreaWidth)
        }

        setInterval( () => {
            this.tickerValue = this.currentScrollPosition
        }, 250)

      this.gameLoop()
    },
    gameLoop() {
        const tick = () => {
            this.currentScrollPosition += 4

            const finishCalc = this.currentScrollPosition - this.clientWindowHeight;
            
            if ( finishCalc >= this.mapLength ) {
                this.totalScore += ( (this.collected - this.totalStrikes) * 50 )
                this.isGameFinished = true
            } else {
                requestAnimationFrame(tick)
                this.checkOresPosition( )
            }
        }
        
        tick()
    },
    strike(){
        if (this.strikesRemaining > 0) {
            this.totalStrikes++
            this.strikesRemaining--
            const hitZoneItems = this.oreStatus.filter(ore => ore.isInHitZone && !ore.destroyed)

            if (hitZoneItems.length > 0) {
                hitZoneItems.forEach(ore => {
                    ore.destroyed = true
                    this.collected++
                    this.totalScore+=100
                })
            }
        }
    },
    checkOresPosition() {
        this.oreStatus.forEach(currentOre => {
            const windowRelativePositionY = currentOre.scrollPosition - this.currentScrollPosition

            if (windowRelativePositionY <= 0 && windowRelativePositionY >= 0 - ( this.clientWindowHeight + this.oreWidth )) {
                currentOre.windowPosition = this.clientWindowHeight + windowRelativePositionY
                currentOre.visible = true

                if ((currentOre.windowPosition) >= ( this.hitZoneTop - this.oreWidth ) && (currentOre.windowPosition + this.oreWidth) <= (this.hitZoneBottom + this.oreWidth)) {
                    currentOre.isInHitZone = true
                } else {
                    currentOre.isInHitZone = false
                }
            } else {
                currentOre.visible = false
            }
        })
    },
  },
  mounted() {
    this.startGame()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.collected{
    top: 0;
    position: absolute;
    right: 0;
    width: 220px;
    background: grey;
    height: 32px;
    text-align: left;
}

.strikes-remaining{
    top: 32px;
    color: red;
    position: absolute;
    right: 0;
    width: 220px;
    background: white;
    height: 32px;
    text-align: left;
}

.strikes-remaining p{
    margin: 5px;
    padding: 5px;
}

.collected img{
    margin: 3px -18px 3px 0;
    max-width: 26px;
}

.game-summary{
    position: absolute;
    transform: translateY(-100%);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    background: #000;
    padding: 20px;
    overflow: hidden;
    font-size: 200%;
}

.game-summary button{
    font-size: 130%;
    padding: 20px 25px;
    margin-top: 20px;
    border-radius: 10px;
}

.game-summary.show{
    transform: translateY(0);
    transition: transform 0.5s ease-in-out;
}

.summary-body .number{
    font-size: 150%;
    font-weight: normal;
}

.game-window{
    background: #000;
    top: 0;
    left: 0;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.ore{
    position: absolute;
    width: 64px;
    height: 64px;
    background: url("../assets/Mining_05_ironore.png");
    background-size: cover;
    display: none;
    filter: drop-shadow(0px 0px 20px #ff0000);
}

.ore.show{
    display: block;
}

.ore.is-in-zone{
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

.ore.destroyed{
    animation: shake-explode 0.25s cubic-bezier(.36,.07,.19,.97) 1 both;
}

.strike-button{
    font-family: "IM Fell English";
    position: absolute;
    height: 50px;
    bottom: 30px;
    width: 100px;
    left: calc( 50% - ( 100px / 2 ) );
}

.strike-button button{
    background: #42b983;
    color: #fff;
    border: none;
    padding: 14px 12px;
    border-radius: 5px;
    font-size: 20px;
    width: 100%;
    cursor: pointer;
}

.strike-button.disabled button{
    background: grey;
}

.strike-button.active{
    animation: grow 0.25s ease-in-out infinite;
}

.ticker{
    width: 100px;
    height: 30px;
    background: #000;
    color: #ff0000;
    font-weight: bold;
    top: 0;
    left: 0;
    text-align: left;
    margin: 0;
    padding: 0;
    position: absolute;
}

.background{
    opacity: 50%;
    background: url("../assets/rocktexture.jpg") repeat;
    background-size: contain;
    width: 50%;
    height: 4096px;
    top: 0;
    margin: 0 auto;
    position: relative;
}

#hit-zone{
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 16px;
    background-color: rgba( 255, 30, 30, 0.5);
    transition: background-color 0.25s linear;
}

#hit-zone.active{
    background-color: rgba( 0, 255, 0, 0.5);
    transition: background-color 0.25s linear;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-2px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, -2px, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-8px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(8px, -8px, 0);
  }
}

@keyframes shake-explode {
  0% {
    transform: translate3d(0, 0, 0), scale(1);
    opacity: 1;
  }

  70% {
    opacity: 0.75
  }

  100% {
    transform: scale(4);
    opacity: 0;
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
